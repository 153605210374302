import React from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import SEO from "../../components/seo"
import Mobile from '../../images/mobile-mock-web.svg'
import Layout from '../../components/layout'

import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from '../../components/CustomForm'

class AlertPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            first_name: '',
            last_name: '',
            street_address: '',
            city: '',
            zip: '',
            mobile: '',
            email: '',
            formError: [],
            formSuccess: '',
        }
    }

    render() {
        return (
            <Layout>
                <SEO title="Alerts" />
                <div className="title-section">
                    <h1 className="title is-size-1">District Alert System</h1>
                </div>
                <div className="content-align" style={{paddingBottom: '3rem'}}>
                            <p>The District has implemented a digital notification system to inform residents quickly of a water,
                            sewer, drainage, or other District related event or situation.</p>

                            <h2 className="is-size-3">When will the notification system be used?</h2>

                            <p>Residents may receive a notification to inform them of situations
                            such as water shutoffs for construction or repair, boil water notices,
                            flood warnings, upcoming major District events such as elections or
                            community events, etc.</p>

                            <h2 className="is-size-3">Who will receive notifications?</h2>

                            <p>Residents will only receive notifications that apply to their
                            neighborhood or zone. For example, a notice about a water outage
                            scheduled for a specific street for repairs will only be sent to those
                            affected in that area. This will limit any unnecessary notifications
                            being sent to those it does not apply to.</p>

                            <h2 className="is-size-3">How to register?</h2>

                            <h3 className="is-size-4" style={{fontWeight: '600'}}>TEXT ALERTS</h3>
                            <p>For Text Alerts, text MUD2 to (720) 782-3673 to receive important news and updates from Mission Bend Municipal Utility District No. 2 via text messages.</p>

                            <p>Message and data rates may apply. To unsubscribe, text STOP MUD2 to (720) 782-3673. For help text HELP anytime. Available in USA. Your privacy is always protected and your information will not be shared.</p>

                            <h3 className="is-size-4" style={{fontWeight: '600'}}>EMAIL ALERTS</h3>
                            <p>Please complete the form below to sign up to receive important news and updates from Mission Bend Municipal Utility District No. 2 via email alerts.</p>
                            <MailchimpSubscribe
                                url="https://mbmud2.us6.list-manage.com/subscribe/post?u=e338102efafea86bf25768d63&amp;id=be1e3943f8"
                                render={({ subscribe, status, message }) => (
                                    <CustomForm
                                    status={status}
                                    message={message}
                                    onValidated={formData => subscribe(formData)}
                                    />
                                )}
                                />

                    <h2 className="is-size-3">Additional Questions</h2>

                    <p>Residents may use the <Link to={`/contact-us`}>Contact Us</Link> page on the website for
                    assistance or to ask any questions.</p>
                </div>
            </Layout>
        )
    }
}

export default AlertPage
